<template>
  <div class="chart">
    <div class="line-chart" id="map" style="height: 100%; width: 100%"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import uploadedDataURL from '@/utils/china.json'
export default {
  name: 'lineChart',
  /**
   * @params chartData 图表数据
   */
  props: {
    chartData: {
      style: Array,
      default: []
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function (val, valOld) {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      const options = {
        // backgroundColor: '#013954',
        geo: {
          map: 'china',
          top:275,
          aspectScale: 0.75, //长宽比
          zoom: 1.68,
          roam: false,
          itemStyle: {
              shadowColor: 'rgb(22, 42, 77)',
              shadowOffsetX: 6,
              shadowOffsetY: 14
          },
          regions: [
            {
              name: '南海诸岛',
              itemStyle: {
                areaColor: 'rgba(0, 10, 52, 1)',
                borderColor: 'rgba(0, 10, 52, 1)',
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                    color: '#009cc9'
                  }
                }
              }
            }
          ]
        },
        series: [
          {
            type: 'map',
            roam: false,
            tooltip:{
                position: ['50%', '50%'],
            
            },
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                textStyle: {
                  color: 'transparent'
                }
              }
            },
            // #123D7E
            // #0F3B7C
            // #184C8F
            // #163069
            // #0E366B
            // #0F356A
            itemStyle: {
              normal: {
                borderColor: '#60C3DD',
                borderWidth: 1,
                areaColor: '#184C8F'
              },
              emphasis: {
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#1D709A' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#1C9EC7' // 100% 处的颜色
                    }
                  ],
                  globalCoord: true // 缺省为 false
                },
                borderWidth: 0.1
              }
            },
            zoom: 1.68,
            top:275,
            map: 'china' //使用
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById('map')
      echarts.registerMap('china', uploadedDataURL)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
<style>
.chart {
  overflow: hidden;
}
</style>
