<template>
  <div class="wrapper-home">
    <div class="home-inner">
      <chinaMap :chartData="chartData" class="chinaMap"/>
      <!-- <div ref="myChart" id="myChart" class="chinaMap"></div> -->
    </div> 
    
    <Lfooter></Lfooter>
  </div>
</template>

<script>
  import Lfooter from '@/views/layout/footer.vue'
import chinaMap from '@/views/components/map.vue'
export default {
  components: { Lfooter,chinaMap },
  data() {
    return {
      charts: '',
      option: {},
      chartData: []
    }
  },
  mounted() {
    this.$echarts.registerMap('china', require('../../utils/china.json')) //获取地图数据
    this.setMyEchart() // 页面挂载完成后执行
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart // 通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart) // 利用原型Echarts的初始化
        console.log(thisChart)
        this.charts = thisChart //保存实例
      }
      let top = 275
      let zoom = 1.68
      let option = {
        // backgroundColor: 'rgba(0,0,0,0)',
        tooltip: {
          show: false
        },
        legend: {
          show: false
        },
        geo: [
          {
            map: 'china',
            // zlevel: 5,
            roam: false,
            layoutCenter: ['50%', '50%'],
            layoutSize: '180%',
            selectedMode: false, //是否允许选中多个区域
            zoom: zoom,
            aspectScale: 0.78,
            show: false
          }
          // {
          //   type: 'map',
          //   map: 'china',
          //   zlevel: -1,
          //   roam: false,
          //   layoutCenter: ['50%', '50%'],
          //   layoutSize: '180%',
          //   selectedMode: false, //是否允许选中多个区域
          //   zoom: zoom,
          //   aspectScale: 0.78,
          //   show: false
          // },
          // {
          //   type: 'map',
          //   map: 'china',
          //   zlevel: -2,
          //   roam: false,
          //   layoutCenter: ['50%', '50%'],
          //   layoutSize: '180%',
          //   selectedMode: false, //是否允许选中多个区域
          //   zoom: zoom,
          //   aspectScale: 0.78,
          //   show: false
          // },
          // {
          //   type: 'map',
          //   map: 'china',
          //   zlevel: -3,
          //   roam: false,
          //   layoutCenter: ['50%', '50%'],
          //   layoutSize: '180%',
          //   selectedMode: false, //是否允许选中多个区域
          //   zoom: zoom,
          //   aspectScale: 0.78,
          //   show: false
          // }
        ],
        series: [
          {
            name: 'MAP',
            type: 'map',
            map: 'china',
            data: [1, 100],
            selectedMode: false, //是否允许选中多个区域
            zoom: zoom,
            geoIndex: 1,
            top: top,
            tooltip: {
              show: true,
              formatter: function (params) {
                if (params.data) {
                  return params.name + '：' + params.data['value']
                } else {
                  return params.name
                }
              },
              backgroundColor: 'rgba(0,0,0,.6)',
              borderColor: 'rgba(147, 235, 248, .8)',
              textStyle: {
                color: '#FFF'
              }
            },
            label: {
              show: false,
              color: '#000',
              // position: [-10, 0],
              formatter: function (val) {
                if (val.data !== undefined) {
                  return val.name.slice(0, 2)
                } else {
                  return ''
                }
              },
              rich: {}
            },
            emphasis: {
              label: {
                show: false
              },
              itemStyle: {
                areaColor: '#389BB7',
                borderWidth: 1
              }
            },
            itemStyle: {
              borderColor: 'rgba(147, 235, 248, .8)',
              borderWidth: 1,
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                  }
                ],
                globalCoord: false // 缺为 false
              },
              shadowColor: 'rgba(128, 217, 248, .3)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10
            }
          }
        ]
      }
      this.option = option
      this.charts.setOption(this.option) // 将编写好的配置项挂载到Echarts上
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-home {
  width: 100%;
  height: 100%;
  .home-inner {
    width: 100%;
    height: 100%;
    background: url('../../assets/img/home-bg.png') no-repeat center center;
    background-size: cover;
  }
  .chinaMap {
    width: 1265px;
    height: 922px;
    margin: 0 auto;
  }
}
</style>
